<template>
  <!-- 
  A Dialog box that opens in two different shapes , sliding down and pop up -in desktop with two different content slots 
  -->
  <div>
    <Teleport to="#after-header-slide">
      <div
        v-if="modelValue"
        :key="2"
        class="bubble [ fixed bottom-0 ] [ w-full z-40 ] [ flex items-end lg:items-center justify-center ]"
        :class="{
          'lg:bottom-auto lg:top-1/2 [ lg:transform lg:-translate-y-1/2 ]': variant !== 'bottom',
          'md:hidden': disableDesktop,
        }"
      >
        <Teleport to="#after-header-fade">
          <div
            v-if="modelValue"
            :key="1"
            class="bg-black fixed inset-0 w-full h-full opacity-50 z-40"
            :class="{
              'md:hidden': disableDesktop,
            }"
            @click="close"
          ></div>
        </Teleport>

        <div
          class="dialog__body w-full lg:w-auto lg:overflow-hidden [ md:py-4 md:px-5 ] bg-page-bg [ flex flex-col ] relative"
          :class="{
            'dialog__body--primary': variant === 'primary',
            'dialog__body--bottom': variant === 'bottom',
            'dialog__body--secondary': variant === 'secondary',
            'dialog__body--warn': variant === 'warn',
            'dialog__body--small': variant === 'small',
          }"
        >
          <button
            :title="$t('close')"
            aria-label="close current open modal"
            class="text-sm [ bg-gray-100 rounded-sm ] [ flex items-center justify-center ] [ w-8 h-8 ] [ absolute top-5 right-5 ] z-50"
            @click="close"
          >
            <svg-icon-close class="text-primary-1-100" width="21" height="21" />
          </button>

          <div class="relative z-10">
            <slot />
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
defineComponent({
  name: 'VariantModalDialog',
});

const props = defineProps({
  modelValue: {
    type: null,
    default: false,
  },
  variant: {
    type: String as PropType<'primary' | 'secondary' | 'warn' | 'bottom' | 'small'>,
    default: 'primary',
    validator: (value: string) => ['primary', 'secondary', 'warn', 'bottom', 'small'].includes(value),
  },
  disableDesktop: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);
function close() {
  emit('update:modelValue', false);
}

watch(
  () => props.modelValue,
  newValue => {
    document.body.classList.toggle('overflow-hidden', newValue);
  }
);
onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));
</script>

<style lang="postcss" scoped>
.dialog {
  &__body {
    @screen lg {
      min-height: auto;
      max-width: 740px;
      min-width: 700px;
    }
    &--primary {
      @apply rounded-t-6sm md:rounded-6sm;
      &::after {
        transform: translate(-50%, 50%);
      }
    }
    &--bottom {
      &::after {
        content: none;
      }
      @screen lg {
        @apply w-full h-full rounded-t-2xs rounded-b-none max-w-full;
      }
    }
    &--secondary {
      &::after {
        left: auto;
        right: 0;
        transform: translate(50%, 50%);
      }
    }
    &--warn {
      &::after {
        @apply rounded-none;
        height: 100vh;
        transform: translateX(-50%);
      }
    }

    &--small {
      @apply rounded-6sm;
      min-width: 500px;
    }
  }
  &__btn {
    @screen lg {
      @apply top-10;
    }
  }
  [dir='rtl'] &__btn {
    transform: translate(50%, -50%);
  }
  &__body--bottom &__btn {
    @screen lg {
      @apply top-19;
    }
  }
}
</style>
<i18n>
{
  "en": {
    "close": "Close"
  }
}
</i18n>
